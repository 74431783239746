import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import FormSimpleAjax from '../contact/FormSimpleAjax.js';
import ExternalLink from '@common/ExternalLink';

import { Section, Container } from '@components/global';
import InstagramIcon from '@static/icons/instagram.svg';
import MailIcon from '@static/icons/mail.svg';
import PhoneIcon from '@static/icons/phone.svg';

import './style.css';

const SOCIAL = [
  {
    icon: InstagramIcon,
    link: 'https://www.instagram.com/kytkypodlemarky_/',
    text: 'kytkypodlemarky_',
  },
  {
    icon: MailIcon,
    link:
      'mailto:poulovamarketa@gmail.com?subject=Vaše%20předmět&body=Váš%20text%20zprávy',
    text: 'poulovamarketa@gmail.com',
  },
  {
    icon: PhoneIcon,
    link: 'tel:+420608002452',
    text: '+420 608 002 452',
  },
];

const Contact = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(typeof window !== 'undefined' && window.innerWidth <= 600);
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  return (
    <StaticQuery
      query={graphql`
        query {
          art_logo_bila: file(
            sourceInstanceName: { eq: "art" }
            name: { eq: "logo_bila" }
          ) {
            childImageSharp {
              fluid(maxWidth: 760) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
          art_main_background: file(
            sourceInstanceName: { eq: "art" }
            name: { eq: "main_background" }
          ) {
            publicURL
            childImageSharp {
              fluid(maxWidth: 760) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => (
        <Section id="kontakt">
          <ContactForm backgroundURL={data.art_main_background.publicURL}>
            <Container>
              <Grid>
                <div>
                  <SocialIcons>
                    <h1>Markéta Poulová</h1>
                    {SOCIAL.map(({ icon, link, text }) => (
                      <ExternalLink
                        key={link}
                        href={link}
                        style={isMobile ? mobileLinkStyle : linkStyle}
                      >
                        <img src={icon} alt="link" />
                        <span>{text}</span>
                      </ExternalLink>
                    ))}
                  </SocialIcons>
                  <br />
                  <FormSimpleAjax name="ContactSimpleFormAJAX" />
                </div>
                <div>
                  <Image>
                    <Img fluid={data.art_logo_bila.childImageSharp.fluid} />
                  </Image>
                </div>
              </Grid>
            </Container>
          </ContactForm>
        </Section>
      )}
    />
  );
};

const SocialIcons = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;

  @media (max-width: ${props => props.theme.screen.md}) {
    // ... přidejte zde pravidla pro média pro mobilní zařízení
  }

  img {
    margin: -8px 1rem;
    width: 28px;
    height: 28px;
  }
`;

const linkStyle = {
  color: 'white',
  fontSize: '1.5rem',
  textDecoration: 'none',
  marginBottom: '10px',
};

const mobileLinkStyle = {
  color: 'white',
  fontSize: '1.3rem',
  textDecoration: 'none',
  marginBottom: '10px',
};

const ContactForm = styled.header`
  font-family: Laviossa, sans-serif;
  background-color: ${props => props.theme.color.primary};
  background-image: url(${props => props.backgroundURL});
  background-size: cover; // Přidání této vlastnosti
  padding-top: 60px;

  @media (max-width: ${props => props.theme.screen.md}) {
    padding-top: 128px;
  }

  h1 {
    color: white;
    padding: 4rem 0 2rem 0;
  }
`;

const Image = styled.header`
  width: 400px;
  height: auto;

  @media (max-width: ${props => props.theme.screen.md}) {
    display: none;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 40px;
  text-align: right;
  align-items: center;
  justify-items: center;

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
  }

  ${props =>
    props.inverse &&
    `
    text-align: left;
    grid-template-columns: 2fr 3fr;
  `}
`;

export default Contact;
