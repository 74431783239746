import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import './style.css';

import { Container } from '@components/global';

const Header = () => (
  <StaticQuery
    query={graphql`
      query {
        art_main_picture: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "main_picture" }
        ) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        art_main_background: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "main_background" }
        ) {
          publicURL
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => (
      <HeaderWrapper backgroundURL={data.art_main_background.publicURL}>
        <Container>
          <Grid>
            <Text>
              <h1 className="main_title">Vítejte</h1>
              <p className="sub_title">
                Kytky podle Marky nabízí kompletní květinové služby ve Svitavách
                a okolí. Specializujeme se na tvorbu květinových dekorací a
                vázání květin pro běžné i slavnostní příležitosti. Používáme
                pouze kvalitní květiny, které vážeme podle vašich představ.
              </p>
              <p className="sub_title">
                Kontaktujte nás pro kompletní svatební květinovou výzdobu nebo
                jen vázanou kytici k narozeninám.
              </p>
            </Text>
            <Art>
              <Img fluid={data.art_main_picture.childImageSharp.fluid} />
            </Art>
          </Grid>
        </Container>
      </HeaderWrapper>
    )}
  />
);

const HeaderWrapper = styled.header`
  background-color: ${props => props.theme.color.primary};
  background-image: url(${props => props.backgroundURL});
  padding-top: 0px;
  background-size: cover;
  background-position: center;
`;

const Art = styled.figure`
  width: 100%;
  margin: 0;
  height: auto;
  display: flex;
  justify-content: flex-end;
  > div {
    width: 130%;
    margin-left: -15%;
    margin-right: -15%;

    @media (max-width: ${props => props.theme.screen.md}) {
      display: none;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-gap: 64px;

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 80px;

    > ${Art} {
      order: 2;
    }
  }
`;

const Text = styled.div`
  justify-self: center;

  .main_title {
    color: white;
    font-size: 10rem;
    margin-bottom: 5rem;
  }

  .sub_title {
    color: white;
    margin-bottom: 1rem;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    justify-self: start;
  }
  .main_title {
    margin-top: 10rem;
    font-size: 7rem;
  }
`;

export default Header;
