import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { Section, Container } from '@components/global';

const Services = () => (
  <StaticQuery
    query={graphql`
      query {
        art_logo_zelene: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "logo_zelene" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <Section id="naše služby">
        <StyledSection>
          <Container>
            <h1>Naše služby</h1>
            <Grid>
              <Art>
                <Img fluid={data.art_logo_zelene.childImageSharp.fluid} />
              </Art>
              <Text>
                <h2>Nabízíme:</h2>
                <ul>
                  <li key="dárkové-kytice">
                    vázané dárkové kytice pro každou příležitost
                  </li>
                  <li key="flowerboxy">flowerboxy</li>
                  <li key="sezónní-věnce">sezónní věnce na dveře</li>
                  <li key="adventní-věnce">adventní věnce a svícny</li>
                  <br />
                  <li>svatební vazba a servis</li>
                  <ul>
                    <li key="svatební-kytice">svatební kytice</li>
                    <li key="náramky-věnečky">náramky, věnečky</li>
                    <li key="slavobrány">slavobrány</li>
                    <li key="květinové-výzdoby">
                      květinové výzdoby vašeho svatebního místa
                    </li>
                  </ul>
                  <br />
                  <li>společenské akce a oslavy</li>
                  <ul>
                    <li key="konzultace">konzultace, dovoz, aranžování</li>
                    <li key="realizace-výzdob">realizace květinových výzdob</li>
                    <li key="maturitní-plesy">maturitní plesy</li>
                  </ul>
                </ul>
                <p>
                  Veškeré květiny či dekorace jsou připravovány na přání
                  zákazníků. Řezané květiny jsou dováženy až 5x týdně.
                  Zaručujeme Vám tedy jejich čerstvost a kvalitu.
                </p>
              </Text>
            </Grid>
          </Container>
        </StyledSection>
      </Section>
    )}
  />
);

const StyledSection = styled(Section)`
  background-color: ${props => props.theme.color.white};
  background-size: cover;
  background-position: center;
  margin-top: 100px;

  h1 {
    font-weight: bold;
    padding-left: 10rem;

    @media (max-width: ${props => props.theme.screen.md}) {
      text-align: center;
      padding-left: 0; /* Remove left padding */
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 2fr 4fr;
  grid-gap: 40px;
  text-align: left;
  align-items: center;
  justify-items: center;
  margin: 24px 0;

  ${props =>
    props.inverse &&
    `
    text-align: left;
    grid-template-columns: 2fr 3fr;
  `}

  h2 {
    margin-bottom: 16px;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: left;
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 24px;
    }

    ${props =>
      props.inverse &&
      `
        ${Art} {
          order: 2;
        }
    `}
  }
`;

const Text = styled.div`
  p {
    margin-bottom: 1rem;
  }

  h1 {
    font-size: 2.5rem;
    margin-bottom: 16px;
  }

  h2 {
    font-size: 2rem;
    margin-bottom: 16px;
  }

  h3 {
    font-size: 1.8rem;
    margin-bottom: 16px;
  }

  ul {
    margin-bottom: 16px;
    padding-left: 20px;
  }

  li {
    margin-bottom: 8px;
    margin-left: 0;
    padding-left: 1em;
  }
`;

const Art = styled.figure`
  margin: 0;
  max-width: 250px;
  width: 100%;

  @media (max-width: ${props => props.theme.screen.md}) {
    display: none;
  }
`;

export default Services;
